import { graphql, navigate, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import SuccessPage from "../../../../components/global/pilotSignupSuccessPage";
const SignUpSuccess = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "green-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      certifiedBadge: file(relativePath: { eq: "certified-badge.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 135, quality: 100)
        }
      }
    }
  `);

  return (
    <SuccessPage
      pageTitle="WE’RE OFF TO A FLYING START"
      image={data.bgImage}
      badge={data.certifiedBadge.childImageSharp.gatsbyImageData}
      message="You’re now a part of the Globhe crowddroning community."
      subMessage="You will now be able to access our platform to attract clients with
      accurate"
      subMessage1="and actionable drone data for a more sustainable world."
      buttonLink="/crowddroning/pilot/sign-in"
    />
  );
};

SignUpSuccess.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      signupCompleted: PropTypes.bool,
      // statusDetails: PropTypes.object,
    }),
  }).isRequired,
};

export default SignUpSuccess;
